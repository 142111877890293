import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useAuthStore = defineStore('authStore', () => {
  const user = ref();
  const isLoggedIn = computed(() => Boolean(user.value?.userid));
  const companyId = computed(() => (user.value?.companyid || ''));
  const companyName = computed(() => (user.value?.companyname || ''));
  const companyDispName = computed(() => (user.value?.companydispname || ''));
  const token = computed(() => (user.value?.token || ''));
  const isImpersonation = computed(() => Boolean(user.value?.impersonation));
  const companyCode = computed(() => (user.value?.companycode || ''));
  const completeflg = computed(() => (user.value?.completeflg || 0));
  const taglinkflg = computed(() => (user.value?.taglinkflg || 0));

  const signIn = (userData) => {
    user.value = userData;
  };

  const signOut = () => {
    user.value = null;
  };

  return {
    user,
    isLoggedIn,
    companyId,
    companyName,
    companyDispName,
    token,
    isImpersonation,
    companyCode,
    completeflg,
    taglinkflg,
    signIn,
    signOut,
  };
});
